
  import SocialSharing from '@/components/modals/SocialSharing.vue';
  import SideBarMenu from "./SideBar/SideBarMenu";
  export default {
    components: {
      SideBarMenu,
      SocialSharing
    },
    props:{
  translucentWhite:{
    type: Boolean,
    default: false
  }
},
    data() {
      return {
        active: false,
        scrolled: false,
        showLoc: false,
        url: null,
        checkHeader: 'default',
        hideSearch: false,
        hideFloatingBar: false,
        homePage: false,
      }
    },
    jsonld() {
      return this.getJsonData();
    },
    computed: {
    crumbs() {
      const fullPath = this.$route.fullPath
      const params = fullPath.startsWith('/')
        ? fullPath.substring(1).split('/')
        : fullPath.split('/')
      let crumbs = []
      let path = ''
      params.forEach((param, index) => {
        path = `${path}/${param}`
        const match = this.$router.match(path)
        if (match.name !== null) {
          crumbs.push({
            title: param.replace(/-/g, ' '),
            ...match,
          })
        }
      })
      let homeObj = {
        fullPath: '/',
        title: 'Home'
      }
      if(fullPath == '/'){
        crumbs = []
      }
      crumbs.unshift(homeObj)
      return crumbs
    },
  },
    created() {
      if (process.browser)
        window.addEventListener('scroll', this.handleScroll);
    },
    watch: {
      $route(to, from) {
        this.url = window.location.href;
        if (to && to.name) {
          if (to.name.includes('-slug') || to.name == 'index' || to.name == 'experiences-category' || to.name == 'explore-category') {
            this.checkHeader = "share";
            this.hideSearch = true;
          } else if (to.name == 'activities') {
            this.checkHeader = "default";
            this.hideSearch = true;
          } else if (to.name == 'index' || to.name == 'search') {
            this.hideSearch = true;
          } else if (to.name == 'blog' || to.name == 'faq' || to.name == 'contact' || to.name == 'cleint-feedbck') {
            this.checkHeader = "default";
            this.hideSearch = false;
          } else {
            this.checkHeader = "default";
            this.hideSearch = true;
          }
          if(to.name.includes('places-slug') || to.name == 'places' || to.name == 'index'){
            this.showLoc = true;
          }else { this.showLoc = false;}

          if(to.name.includes('activities-slug') || to.name.includes('checkout')){
            this.hideFloatingBar = true;
          }else{
            this.hideFloatingBar = false;
          }
          if(to.name == 'index'){
            this.homePage = true
          }else {this.homePage = false}
        }
      }
    },
    mounted() {
      this.url = window.location.href;
    },
    updated() {
      let ref = this.$route.query.utm_source ? this.$route.query.utm_source : null;
      if (ref && ref.trim() != '') {
        localStorage.setItem("refCode", ref);
      }
      let prop = this.$route.query.utm_property ? this.$route.query.utm_property : null;
      if (prop && prop.trim() != '') {
        localStorage.setItem("refProp", prop);
      }
      this.url = window.location.href;
      this.checkHeaderType();
    },
    methods: {
      handleScroll() {
        this.scrolled = window.scrollY > 0;
      },
      checkHeaderType() {
        if ($nuxt.$route.name) {
          if ($nuxt.$route.name.includes('-slug') || $nuxt.$route.name == 'index'|| $nuxt.$route.name == 'experiences-category' || $nuxt.$route.name == 'explore-category') {
            this.checkHeader = "share";
            this.hideSearch = true;
          } else if ($nuxt.$route.name == 'activities') {
            this.checkHeader = "default";
            this.hideSearch = true;
          } else if ($nuxt.$route.name == 'index' || $nuxt.$route.name == 'search') {
            this.hideSearch = true;
          } else if ($nuxt.$route.name == 'blog' || $nuxt.$route.name == 'faq' || $nuxt.$route.name == 'contact' ||
            $nuxt.$route.name == 'cleint-feedbck') {
            this.checkHeader = "default";
            this.hideSearch = false;
          } else {
            this.checkHeader = "default";
            this.hideSearch = true;
          }
          if($nuxt.$route.name.includes('places-slug') || $nuxt.$route.name == 'places' || $nuxt.$route.name == 'index'){
            this.showLoc = true;
          }else { this.showLoc = false;}
          if($nuxt.$route.name.includes('activities-slug') || $nuxt.$route.name.includes('checkout') ){
            this.hideFloatingBar = true;
          }else{
            this.hideFloatingBar = false;
          }
          if($nuxt.$route.name == 'index'){
            this.homePage = true
          }else {this.homePage = false}
        }
      },
      call() {
        this.active = !this.active;
        let self = this;
        window.addEventListener('click', function (e) {
          // close dropdown when clicked outside
          if (!self.$el.contains(e.target)) {
            self.active = false
          }
        })
      },
      activeClose(value) {
        this.active = value;
      },
      getJsonData() {
        if(!process.client){
          return null;
        }

      let structure = {
        "@context": "http://schema.org"
      }
      let data = [{
        "@context": "http://schema.org",
        "@type": "Organization",
        "name": "Goa App",
        "alternateName": "Goa Tourism Travel",
        "image": "https://goa.app/goa-app-logo.svg",
        "logo": "https://goa.app/goa-app-logo.svg",
        "url": "https://goa.app/",
        "foundingDate": "2016",
        "telephone": "+91 9049248689",
        "founders": [{
            "@type": "Person",
            "name": "Sanjeet Shet"
          },
          {
            "@type": "Person",
            "name": "Radhaprasad Borkar"
          }
        ],
        "contactPoint" : [{
          "@type" : "ContactPoint",
          "telephone" : "+91 9049248689",
          "email": "help@goa.app",
          "url": "https://goa.app/contact",
          "contactType" : "customer service",
          "areaServed" : "IN",
					"availableLanguage" : ["English", "Hindi", "Marathi", "Tamil", "Telugu", "Kannada", "Gujarati", "Punjabi"]
        }],
        "sameAs": ["https://twitter.com/GoaApp","https://www.facebook.com/goaapp","https://www.instagram.com/goa.app/?hl=en", "https://www.linkedin.com/company/goaapp", "https://www.youtube.com/channel/UCyOmQeyK8GYe2LZA1WagMhw"],
        "address": {
          "@type": "PostalAddress",
          "streetAddress": "Atal Incubation Centre (AIC)",
          "addressLocality": "Goa",
          "postalCode": "403505",
          "addressCountry": "IN"
	      },
        "geo": {
          "@type": "GeoCoordinates",
          "latitude": 15.5683517,
          "longitude": 74.0274481
        },
        "openingHoursSpecification": [{
          "@type": "OpeningHoursSpecification",
          "dayOfWeek": [
            "Monday",
            "Tuesday"
          ],
          "opens": "09:00",
          "closes": "18:00"
        },{
          "@type": "OpeningHoursSpecification",
          "dayOfWeek": [
            "Wednesday",
            "Thursday",
            "Friday"
          ],
          "opens": "09:00",
          "closes": "21:00"
        },{
          "@type": "OpeningHoursSpecification",
          "dayOfWeek": "Saturday",
          "opens": "09:00",
          "closes": "17:00"
        },{
          "@type": "OpeningHoursSpecification",
          "dayOfWeek": "Sunday",
          "opens": "09:00",
          "closes": "19:00"
        }]
      },
		{
		    "@context": "http://schema.org",
		    "@type": "SiteNavigationElement",
		    "name": [
		        "Activities",
		        "Rentals",
            "Taxi",
            "Events",
            "Tour Packages",
            "Tourist Places",
            "Restaurants",
            "Blog",
            "About",
            "Contact Us",
            "FAQ"
		    ],
		    "url": [
		        "https://goa.app/activities",
		        "https://goa.app/rentals",
            "https://goa.app/taxi",
            "https://goa.app/events",
            "https://goa.app/packages",
            "https://goa.app/places",
            "https://goa.app/restaurants",
            "https://goa.app/blog",
            "https://goa.app/about",
            "https://goa.app/contact",
            "https://goa.app/faq"
		    ]
		}
  ];
      let sitesearch = {
        "@type": "WebSite",
        "@id": "https://goa.app/#website",
        "url": "https://goa.app/",
        "name" : "Goa App",
        "description" : "Goa Tourism Travel Guide",
        "alternateName" : "Goa.app",
        "inLanguage" : 'en-US'
      }
      let brdcumbs = null;
      let brdCrumbList = null;
if(this.crumbs){
      brdcumbs = this.crumbs.map((item, index) => ({
      '@type': 'ListItem',
      position: index + 1,
      item: {
        '@id':"https://goa.app"+ item.fullPath,
        'name': item.title,
      },
    }));
  }
if(brdcumbs){
    brdCrumbList = {
      '@type': 'BreadcrumbList',
        "url": "https://goa.app/",
        itemListElement: brdcumbs,
      }
      data.push(brdCrumbList)
    }

      data.push(sitesearch)
      let ds = {
        "@graph": data
      }
      return { ...structure, ...ds };
    },
    }
  }

